import "../assets/styles/resultPageSettings.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MainNavbar } from "./Common/Navbar";
import QuizApi from "../services/QuizApi";

export default function Resultpage() {
  const { quizId } = useParams();
  const [resultSettings, setResultSettings] = useState({
    showpassfail: false,
    showscore: false,
    showcustommsg: true,
    showanswerreport: false,
    custommessage: "Your answers have been successfully submitted",
    passcustommessage: "Congratulation! You passed",
    failcustommessage: "Sorry! You did not pass",
    scorecustommessage: "Your Score is (_)",
  });

  const handleFocus = (event) => event.target.select();

  useEffect(() => {
    try {
      QuizApi.get(`/view-result-settings/${quizId}/`).then((response) => {
        setResultSettings(response.data);
      });
    } catch (error) {
      console.error("Error getting quiz settings:", error);
    }
  }, [quizId]);

  const handleInputChange = (key, value) => {
    const updatedSettings = {
      ...resultSettings,
      [key]: value,
    };

    updateBackendData(updatedSettings);
  };

  const handleCheckboxClick = (key) => {
    const updatedSettings = {
      ...resultSettings,
      [key]: !resultSettings[key],
    };

    updateBackendData(updatedSettings);
  };

  const updateBackendData = (data) => {
    QuizApi.patch(`/view-result-settings/${quizId}/`, data)
      .then((response) => {
        setResultSettings(data); // Update the state
      })
      .catch((error) => {
        console.error("Error updating settings in the backend:", error);
      });
  };

  return (
    <>
      <MainNavbar />
      <div className="sidebar-main">
        <div className="sidebar-additional">
          <p id="select_title">
            Select the result you would like to <br></br> display at the end of
            quiz:
          </p>
          <div
            className="dropdown"
            id="tr2"
            onClick={() => handleCheckboxClick("showpassfail")}
          >
            <div className="restoremodelinksinner">
              <input
                type="checkbox"
                name=""
                id="showpassfail"
                checked={resultSettings.showpassfail}
                onChange={() => handleCheckboxClick("showpassfail")}
              />
            </div>
            <div className="restoremodelinksoutter">Show Pass or Fail</div>
          </div>

          <div
            className="dropdown"
            id="tr3"
            onClick={() => handleCheckboxClick("showscore")}
          >
            <div className="restoremodelinksinner">
              <input
                type="checkbox"
                name=""
                id="showscore"
                checked={resultSettings.showscore}
                onChange={() => handleCheckboxClick("showscore")}
              />
            </div>
            <div className="restoremodelinksoutter">Show Score</div>
          </div>

          <div
            className="dropdown"
            id="tr1"
            onClick={() => handleCheckboxClick("showcustommsg")}
          >
            <div className="restoremodelinksinner">
              <input
                type="checkbox"
                name=""
                id="showcustommsg"
                checked={resultSettings.showcustommsg}
                onChange={() => handleCheckboxClick("showcustommsg")}
              />
            </div>
            <div className="restoremodelinksoutter">Show Custom Message</div>
          </div>

          <div
            className="dropdown"
            id="tr4"
            onClick={() => handleCheckboxClick("showanswerreport")}
          >
            <div className="restoremodelinksinner">
              <input
                type="checkbox"
                name=""
                id="showanswerreport"
                checked={resultSettings.showanswerreport}
                onChange={() => handleCheckboxClick("showanswerreport")}
              />
            </div>
            <div className="restoremodelinksoutter">Show Answer Report</div>
          </div>
        </div>
      </div>

      <div className="superior-containermain">
        <div className="containermain">
          <div className="maincontainer resultContainer">
            {resultSettings.showcustommsg && (
              <div id="showcustomcontentatend">
                <div
                  id="displaymessageatend"
                  className="displaymessageatenddiv unselectableHeading"
                >
                  <span className="endmessagespanele">
                    Display message at the end of the quiz
                  </span>
                </div>
                <input
                  id="editor1"
                  type="text"
                  value={resultSettings.custommessage}
                  placeholder="Type your message when all answers are submitted..."
                  className="displaymessageatendstyle"
                  onBlur={(e) =>
                    handleInputChange("custommessage", e.target.value)
                  }
                  onChange={(e) =>
                    handleInputChange("custommessage", e.target.value)
                  }
                />
                <br />
                <br />
                <br />
              </div>
            )}

            {resultSettings.showpassfail && (
              <div id="showpassfailatend">
                <div
                  id="displaymessageatend"
                  className="displaymessageatenddiv unselectableHeading"
                >
                  <span className="endmessagespanele">
                    Display Pass message at the end of the quiz
                  </span>
                </div>
                <input
                  id="pass"
                  type="text"
                  className="displaymessageatendstyle"
                  placeholder="Display message when quiz taker has passed..."
                  defaultValue={resultSettings.passcustommessage}
                  onBlur={(e) =>
                    handleInputChange("passcustommessage", e.target.value)
                  }
                  onChange={(e) =>
                    handleInputChange("passcustommessage", e.target.value)
                  }
                />
                <br />
                <br />
                <h4>- - - - - - - - - - - - - Or - - - - - - - - - - - - - </h4>
                <br />
                <div
                  id="displaymessageatend"
                  className="displaymessageatenddiv unselectableHeading"
                >
                  <span className="endmessagespanele">
                    Display Fail message at the end of the quiz
                  </span>
                </div>
                <input
                  id="fail"
                  placeholder="Display message when quiz taker has failed..."
                  className="displaymessageatendstyle"
                  defaultValue={resultSettings.failcustommessage}
                  onBlur={(e) =>
                    handleInputChange("failcustommessage", e.target.value)
                  }
                  onChange={(e) =>
                    handleInputChange("failcustommessage", e.target.value)
                  }
                />
                <br />
                <br />
              </div>
            )}

            {resultSettings.showscore && (
              <div id="displayscoreatend">
                <div className="displaymessageatenddiv unselectableHeading">
                  <span className="endmessagespanele">
                    Display Score at the end of the quiz
                  </span>
                </div>
                <input
                  id="score"
                  placeholder="Please write a message..."
                  className="displaymessageatendstyle"
                  contentEditable="true"
                  defaultValue={resultSettings.scorecustommessage}
                  onBlur={(e) =>
                    handleInputChange("scorecustommessage", e.target.value)
                  }
                  onChange={(e) =>
                    handleInputChange("scorecustommessage", e.target.value)
                  }
                />
                <h4 id="h4text">You may use tags for dynamic data score.</h4>
                <br />
              </div>
            )}
          </div>
          <br />
        </div>
      </div>
    </>
  );
}
