import React, { useEffect, useRef, useState } from 'react'
import { Link,  useNavigate,  useParams, useSearchParams } from 'react-router-dom';
import QuizApi, { BACKEND_URL } from '../services/QuizApi';
import { MainNavbar } from './Common/Navbar';
import { format } from "date-fns";
import ContentEditable from 'react-contenteditable';
import "../assets/styles/quizReport.css";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";



export default function QuizReport() {
    const { quizId } = useParams();
    const { id } = useParams();
    const [quizDetails, setQuizDetails] = useState();
    const [quizResult, setQuizResult] = useState();
    const [updatedQuestion, setUpdatedQuestion] = useState([]);
    const nonGradQuestion = ["essay", "add_document"];

    const [answerCorrectly, setAnswerCorrectly] = useState([]);
    const [answerIncorrectly, setAnswerIncorrectly] = useState([]);
    const [nonGradedResponse, setNonGradedResponse] = useState([]);

    const [answerSkipped, setAnswerSkipped] = useState([]);
    const [answerTimeout, setAnswerTimeout] = useState([]);

    const [displayQuestion, setDisplayQuestion] = useState(updatedQuestion);
    const TagVerbose = { "correct": "Correct Answer", "incorrect": "Incorrect Answer", 'skipped': "Skipped Question", 'timeout': "Timed-Out Unanswered", "answered": "Answered" };
    const [extraQuizResult, setExtraQuizResult] = useState([]);
    const quizReportList = useRef([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const resultSortBy = searchParams.get("sort") || "-quizstarttime";
    const [activeQuizReportId, setActiveQuizReportId] = useState(id);
    let navigate = useNavigate();
    const quizIdTitles = useRef({});   
    const [activeQuizStatus, setActiveQuizStatus] = useState("");

    const getAnswerById = (question_id, quizResultData) => {
        return quizResultData?.quiz_answers?.filter((obj) => obj.question_id === question_id)[0];
    }

    
    useEffect(() => {
        if (!sessionStorage.getItem("quizId")) {
            sessionStorage.setItem("quizId", quizId);
        }
    }, [])

    const getQuizReportId = (action)=>{
        if(action === 'next'){
            return parseInt(sessionStorage.getItem("next_quiz_report_id"));
        }else{
            return parseInt(sessionStorage.getItem("previous_quiz_report_id"));
        }
    }

    const updateQuestion = (quizResultData) => {
        let updatedData = [];
        for (let i = 0; i < quizResultData.quiz_questions.length; i++) {
            const question = quizResultData.quiz_questions[i];


            let question_tag = "timeout";

            let answer = getAnswerById(question.question_id, quizResultData);

            let question_graded_nongraded = 'graded';
            if (nonGradQuestion.includes(question.question_type)) {
                question_graded_nongraded = 'non-graded';
            }

            if (typeof answer === 'undefined') {
                question.question_tag = question_tag;
                question.question_graded_nongraded = question_graded_nongraded;
                updatedData.push(question);
                continue;
            }

            if (question_graded_nongraded === 'graded') {
                if (answer.is_correct) {
                    question_tag = 'correct';
                } else {
                    question_tag = 'incorrect';
                }

                if (answer.is_skipped) {
                    question_tag = 'skipped';
                }

            } else {
                if (!answer) {
                    question_tag = 'timeout';

                } else if (answer?.is_skipped) {
                    question_tag = 'skipped';
                }
                else {
                    question_tag = 'answered';
                }
            }
            question.question_tag = question_tag;
            question.question_graded_nongraded = question_graded_nongraded;
            question.answer = answer.answer;
            question.file_answer = answer.file_answer;
            updatedData.push(question);
        }

        if (parseInt(quizResultData?.quiz_result?.total_number_of_questions) < updatedData.length) {
            let time_out_question = updatedData.filter(question => { return question.question_tag === 'timeout' });
            const total_remove_questions = updatedData.length - parseInt(quizResultData?.quiz_result?.total_number_of_questions)
            time_out_question = time_out_question.splice(0, time_out_question.length - total_remove_questions);

            updatedData = updatedData.filter((question) => {
                if (question.question_tag === 'timeout') {
                    if (time_out_question.includes(question)) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                return true;
            });

        }

        updatedData.map((question) => {
            if (quizResultData?.quiz_result?.is_security_submit) {
                if (question.question_tag === 'timeout') {
                    question.question_tag = 'skipped';
                }
            }
            return question;
        })

        return updatedData;
    };

    const getIPBrowser = (deviceInfo) => {
        let IP_address = "";
        let Device_info = "";
        if (deviceInfo) {
            const deviceInfoString = JSON.parse(deviceInfo).Device_info;

            let windowsVersionMatch = deviceInfoString.match(/\(([^)]+)\)/)[1].split('; ');
            if (windowsVersionMatch.length > 2) {
                windowsVersionMatch = windowsVersionMatch.slice(0, -2);
            }
            let windowsVersion = windowsVersionMatch.join(' ');
            if (windowsVersion.includes("NT")) {
                windowsVersion = windowsVersion.replace("NT", "");
            }
            const browserInfoMatch = deviceInfoString.match(/(Chrome|Firefox|Safari|Edge)\/([\d.]+)/);
            const browserInfo = browserInfoMatch ? `${browserInfoMatch[1]} ${browserInfoMatch[2]}` : null;

            IP_address = JSON.parse(deviceInfo).IP_address;
            Device_info = `${browserInfo}, ${windowsVersion}`;
        }
        return { IP_address, Device_info };
    }

    const deleteReport = () => {
        localStorage.setItem("reportDelete", activeQuizReportId);
        QuizApi.delete(`/quiz-result/${activeQuizReportId}`).then(resp => {
            if(extraQuizResult.length > 0){
                if(parseInt(activeQuizReportId) === parseInt(id)){
                    navigate(`/quiz-report/${extraQuizResult[0]?.quizid}/${extraQuizResult[0]?.id}/?sort=${resultSortBy}`);
                    setActiveQuizReportId(extraQuizResult[0]?.id);
                    window.history.replaceState({ idx: 0 }, "", `/quiz-report/${extraQuizResult[0]?.quizid}/${extraQuizResult[0]?.id}/?sort=${resultSortBy}`);
                }else{
                    setActiveQuizReportId(id);
                    setDisplayQuestion(updatedQuestion);
                    setExtraQuizResult(extraQuizResult.filter((extraQuiz) => {
                        if (extraQuiz.id === activeQuizReportId) {
                            return false;
                        }
                        return true;
                    }));
                    quizReportList.current = quizReportList.current.filter((id)=>{
                        if(activeQuizReportId === id){
                            return false;
                        }
                        return true;
                    })
                }
            }
            else{
                if(getQuizReportId('next') === parseInt(id)){
                    navigate(`/quiz-result/${quizId}/`);
                }else{
                    navigate(`/quiz-report/${sessionStorage.getItem('quizId')}/${getQuizReportId('next')}/?sort=${resultSortBy}`);
                }
            }
        }
        ).catch(error => console.log(error));
    }


    useEffect(() => {
        QuizApi.get(`/quiz/${quizId}`).then(resp => {
            setQuizDetails(resp.data);
        }).catch(error => console.log(error));

        let qu = sessionStorage.getItem('qu') || "?so=-quizstarttime&fvk=ALL&limit=20&page=0&sch=[]";
        let backUrl = `/quiz-result-by-user/${id}?sort_by=${resultSortBy}&active_report_id=${activeQuizReportId}`
        let urlParams = new URLSearchParams(qu);
        const start_time = urlParams.get("st");
        const end_time = urlParams.get("et");
        const search = urlParams.get("se");
        if (start_time && end_time) {
            backUrl += `&start_time=${start_time}&end_time=${end_time}`;
        }
        if (search) {
            backUrl += `&search=${search}`;
        }
        
        QuizApi.get(backUrl).then(resp => {
            setQuizResult(resp.data);
            setActiveQuizStatus(resp.data?.quiz_result?.status);
            if (parseInt(sessionStorage.getItem("quizId")) === resp?.data?.quiz_result?.quizid) {
                sessionStorage.setItem("next_quiz_report_id", resp?.data?.quiz_result?.next_quiz_report_id);
                sessionStorage.setItem("previous_quiz_report_id", resp?.data?.quiz_result?.previous_quiz_report_id);
            }
            setUpdatedQuestion(updateQuestion(resp.data));
            setExtraQuizResult(resp?.data?.extra_quiz_result);
            quizReportList.current = [resp?.data?.quiz_result.id, ...resp?.data?.extra_quiz_result?.map((extraQuiz) => extraQuiz?.id)];

            let quizAndTitle =  {[resp?.data?.quiz_result?.id]: resp?.data?.quiz_result?.quiz_title};
            for(let i = 0; i < resp?.data?.extra_quiz_result.length; i++){
                quizAndTitle[resp?.data?.extra_quiz_result[i]?.id] = resp?.data?.extra_quiz_result[i]?.quiz_title;
            }

            quizIdTitles.current = {...quizIdTitles.current,...quizAndTitle};
            quizReportList.current = [resp?.data?.quiz_result.id, ...resp?.data?.extra_quiz_result?.map((extraQuiz) => extraQuiz.id)];
        }).catch(error => console.log(error));
    }, [id]);

    useEffect(() => {
        if (updatedQuestion) {
            setAnswerCorrectly(updatedQuestion.filter(question => (question.question_graded_nongraded === 'graded' && question.question_tag === 'correct')))
            setAnswerIncorrectly(updatedQuestion.filter(question => (question.question_graded_nongraded === 'graded' && question.question_tag === 'incorrect')))
            setNonGradedResponse(updatedQuestion.filter(question => (question.question_graded_nongraded === 'non-graded' && question.question_tag === 'answered')))
            setAnswerSkipped(updatedQuestion.filter(question => (question.question_tag === 'skipped')))
            setAnswerTimeout(updatedQuestion.filter(question => (question.question_tag === 'timeout')))
        }
        setDisplayQuestion(updatedQuestion);
    }, [updatedQuestion]);


    const updateActiveQuizReportId = (action) => {
        // document.getElementById("securityalertheader")?.scrollIntoView({block: "center", behavior: "smooth"});
        if(action === 'next'){
            if(quizReportList.current.indexOf(parseInt(activeQuizReportId)) + 1 < quizReportList.current.length){
                setActiveQuizReportId(quizReportList.current[quizReportList.current.indexOf(parseInt(activeQuizReportId)) + 1]); setDisplayQuestion(extraQuizResult.filter((extraQuiz) => { return extraQuiz.id === quizReportList.current[quizReportList.current.indexOf(parseInt(activeQuizReportId)) + 1] })[0]?.questions);
            }else{
                navigate(`/quiz-report/${sessionStorage.getItem('quizId')}/${getQuizReportId('next')}/?sort=${resultSortBy}`);
            }
        }
        else{
            if(quizReportList.current[1] === activeQuizReportId){
                setActiveQuizReportId(quizReportList.current[0]); setDisplayQuestion(updatedQuestion);
            }
            else if(quizReportList.current.indexOf(parseInt(activeQuizReportId)) - 1 >= 0){
                setActiveQuizReportId(quizReportList.current[quizReportList.current.indexOf(parseInt(activeQuizReportId)) - 1]); setDisplayQuestion(extraQuizResult.filter((extraQuiz) => { return extraQuiz.id === quizReportList.current[quizReportList.current.indexOf(parseInt(activeQuizReportId)) - 1] })[0]?.questions);
            }else{
                navigate(`/quiz-report/${sessionStorage.getItem('quizId')}/${getQuizReportId('prev')}/?sort=${resultSortBy}`);
            }
        }
    }

    useEffect(()=>{
        setActiveQuizReportId(id);
    },[id]);

    useEffect(() => {
        if(quizResult){
            const quizzesReport = [quizResult?.quiz_result,...quizResult?.extra_quiz_result];
            setActiveQuizStatus(quizzesReport.filter((quiz) => { return quiz.id === parseInt(id) })[0]?.status);
        }
    },[activeQuizReportId]);

    

    return (
        <>
            <MainNavbar />
            <div style={{ width: "90%", margin: "auto" }}>
                <div className="quiz-report-containermain">
                    {quizResult && quizDetails && (
                        <div className="report_informationsection">
                            <div className="Quizzer_info">
                                <div className="otherquiztakenheader">
                                    Quiz Taken by:{" "}
                                    <span style={{ fontWeight: 400 }}>
                                        &nbsp;{quizResult.quiz_result.name}
                                    </span>
                                </div>
                                {quizResult.quiz_result.email !== "-" && (
                                    <div style={{ width: "30%", textAlign: "center" }}>
                                        <b>Email:</b> {quizResult.quiz_result.email}
                                    </div>
                                )}
                                {quizResult.quiz_result.phonenumber !== "" && (
                                    <div style={{ width: "30%", textAlign: "end" }}>
                                        <b>Mobile No:</b> {quizResult.quiz_result.phonenumber}
                                    </div>
                                )}
                            </div>
                            {quizResult.quiz_result.quizstarttime && (
                                <table className="otherquiztaken">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "20%" }}>Quiz Name</th>
                                            <th style={{ width: "10%" }}>Date <br /> Time</th>
                                            <th>Score</th>
                                            <th>Result</th>
                                            <th>
                                                Answered
                                                <br />
                                                Correctly
                                            </th>
                                            <th>
                                                Answered
                                                <br />
                                                Incorrectly
                                            </th>
                                            <th>
                                                Non Graded
                                                <br />
                                                Response
                                            </th>
                                            <th>Skipped</th>
                                            <th>Timed Out</th>
                                            <th style={{ width: "18%" }}>IP Address and Browser</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className={`${(parseInt(quizResult?.quiz_result?.id) === parseInt(activeQuizReportId)) && "active-report "}`}>
                                            <td className="is_link quiz-title" onClick={() => {setDisplayQuestion(updatedQuestion); setActiveQuizReportId(quizResult?.quiz_result?.id);}}>{quizDetails.quiztitle}</td>
                                            <td>

                                                {format(
                                                    new Date(quizResult.quiz_result.quizstarttime),
                                                    "dd MMM yyyy"
                                                )}
                                                <br />
                                                {
                                                    format(
                                                        new Date(quizResult?.quiz_result.quizstarttime), " hh:mm aa"
                                                    )
                                                }
                                            </td>
                                            <td>{quizResult.quiz_result.status === "In Progress" ? "-" : quizResult.quiz_result.percentage + "%"}</td>
                                            <td style={{ textTransform: "capitalize", color: `${quizResult?.quiz_result.result === 'pass' ? 'green' : quizResult?.quiz_result.result === 'non graded' ? 'black' : 'red'}` }} >
                                                {quizResult.quiz_result.status === "In Progress" ? "-" : quizResult.quiz_result.result}
                                            </td>
                                            <td className={`${answerCorrectly.length !== 0 && "is_link"}`} onClick={() => {answerCorrectly.length !== 0 && setDisplayQuestion(answerCorrectly); answerCorrectly.length !== 0 && setActiveQuizReportId(quizResult?.quiz_result?.id); }}>
                                                {" "}
                                                {quizResult.quiz_result.status === "In Progress" ? "-" : answerCorrectly.length}
                                            </td>
                                            <td className={`${answerIncorrectly.length !== 0 && "is_link"}`} onClick={() => {answerIncorrectly.length !== 0 && setDisplayQuestion(answerIncorrectly); answerIncorrectly.length !== 0 && setActiveQuizReportId(quizResult?.quiz_result?.id);}}>
                                                {" "}
                                                {quizResult.quiz_result.status === "In Progress" ? "-" : answerIncorrectly.length}
                                            </td>
                                            <td className={`${nonGradedResponse.length !== 0 && "is_link"}`} onClick={() => {nonGradedResponse.length !== 0 && setDisplayQuestion(nonGradedResponse); nonGradedResponse.length !== 0 && setActiveQuizReportId(quizResult?.quiz_result?.id);}}>
                                                {" "}
                                                {quizResult.quiz_result.status === "In Progress" ? "-" : nonGradedResponse.length}
                                            </td>
                                            <td className={`${answerSkipped.length !== 0 && "is_link"}`} onClick={() => {answerSkipped.length !== 0 && setDisplayQuestion(answerSkipped); answerSkipped.length !== 0 && setActiveQuizReportId(quizResult?.quiz_result?.id);}}>
                                                {quizResult.quiz_result.status === "In Progress" ? "-" : answerSkipped.length}
                                            </td>
                                            <td className={`${answerTimeout.length !== 0 && "is_link"}`} onClick={() => {answerTimeout.length !== 0 && setDisplayQuestion(answerTimeout); answerTimeout.length !== 0 && setActiveQuizReportId(quizResult?.quiz_result?.id);}}>
                                                {quizResult.quiz_result.status === "In Progress" ? "-" : answerTimeout.length}
                                            </td>
                                            <td>
                                                {quizResult &&
                                                    quizResult.quiz_result &&
                                                    quizResult.quiz_result.deviceinfo && (
                                                        <>
                                                            <div>
                                                                IP Address:{" "}
                                                                {getIPBrowser(quizResult?.quiz_result?.deviceinfo).IP_address}
                                                            </div>
                                                            <div>
                                                                {getIPBrowser(quizResult?.quiz_result?.deviceinfo).Device_info}
                                                            </div>
                                                        </>
                                                    )}
                                            </td>
                                        </tr>
                                        {
                                            extraQuizResult.map((extraQuiz, index) => {
                                                const answerCorrectly = extraQuiz?.questions.filter(question => (question.question_graded_nongraded === 'graded' && question.question_tag === 'correct'))
                                                const answerIncorrectly = extraQuiz?.questions.filter(question => (question.question_graded_nongraded === 'graded' && question.question_tag === 'incorrect'))
                                                const nonGradedResponse = extraQuiz?.questions.filter(question => (question.question_graded_nongraded === 'non-graded' && question.question_tag === 'answered'))
                                                const answerSkipped = extraQuiz?.questions.filter(question => (question.question_tag === 'skipped'))
                                                const answerTimeout = extraQuiz?.questions.filter(question => (question.question_tag === 'timeout'))

                                                return (
                                                    <tr key={index} className={`${(extraQuiz?.id === parseInt(activeQuizReportId)) && "active-report "}`}>
                                                        <td className="quiz-title is_link" onClick={() => {setDisplayQuestion(extraQuiz?.questions);setActiveQuizReportId(extraQuiz?.id);} }>
                                                            {extraQuiz?.quiz_title}
                                                        </td>
                                                        <td >
                                                            {format(
                                                                new Date(extraQuiz?.quiz_start_time),
                                                                "dd MMM yyyy"
                                                            )}
                                                            <br />
                                                            {
                                                                format(
                                                                    new Date(extraQuiz?.quiz_start_time), " hh:mm aa"
                                                                )
                                                            }
                                                        </td>
                                                        <td >{extraQuiz?.percentage}%</td>
                                                        <td style={{ textTransform: "capitalize", color: `${extraQuiz.result === 'fail' ? 'red' : 'green'}` }}>{extraQuiz?.result}</td>
                                                        <td className={`${answerCorrectly.length !== 0 && "is_link"}`} onClick={() => { answerCorrectly?.length !==0 && setDisplayQuestion(answerCorrectly); answerCorrectly?.length !==0 && setActiveQuizReportId(extraQuiz?.id); }} >{answerCorrectly.length}</td>
                                                        <td className={`${answerIncorrectly.length !== 0 && "is_link"}`} onClick={() => { answerIncorrectly?.length !==0 && setDisplayQuestion(answerIncorrectly); answerIncorrectly?.length !==0 && setActiveQuizReportId(extraQuiz?.id); }}>{answerIncorrectly.length}</td>
                                                        <td className={`${nonGradedResponse.length !== 0 && "is_link"}`} onClick={() => { nonGradedResponse?.length !==0 && setDisplayQuestion(nonGradedResponse); nonGradedResponse?.length !==0 && setActiveQuizReportId(extraQuiz?.id); }}>{nonGradedResponse.length}</td>
                                                        <td className={`${answerSkipped.length !== 0 && "is_link"}`} onClick={() => { answerSkipped?.length !==0 && setDisplayQuestion(answerSkipped); answerSkipped?.length !==0 && setActiveQuizReportId(extraQuiz?.id); }}>{answerSkipped.length}</td>
                                                        <td className={`${answerTimeout.length !== 0 && "is_link"}`} onClick={() => { answerTimeout?.length !==0 && setDisplayQuestion(answerTimeout); answerTimeout?.length !==0 && setActiveQuizReportId(extraQuiz?.id); }}>{answerTimeout.length}</td>
                                                        <td>
                                                            <div>
                                                                IP Address:{" "}
                                                                {getIPBrowser(extraQuiz?.device_info).IP_address}
                                                            </div>
                                                            <div>
                                                                {getIPBrowser(extraQuiz?.device_info).Device_info}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            )}
                        </div>
                    )}
                    <div className="quiz-title-head" style={{marginTop:"12px"}}>{quizIdTitles.current[activeQuizReportId]}</div>
                    <div className="topoptions"> 
                        <div>
                        {
                                parseInt(window.history.length) !== 0 ? (
                                    <button className="btnquiz" onClick={() => { navigate(`/quiz-result/${sessionStorage.getItem('quizId')}/${sessionStorage.getItem('qu')||0}`) }}>Back</button>
                                ) : (
                                    <br></br>
                                )
                            }
                            {
                                (getQuizReportId('prev') !== parseInt(id) || extraQuizResult.length !==0) &&
                                <button className="btnquiz" onClick={() => { updateActiveQuizReportId('prev') }} ><IoMdArrowRoundBack /></button>
                            }
                            {
                                (getQuizReportId('next') !== parseInt(id) || extraQuizResult.length !==0) &&
                                <button className="btnquiz" onClick={() => { updateActiveQuizReportId('next') }} ><IoMdArrowRoundForward /></button>
                            }
                            <button className="btnquiz" onClick={() => deleteReport()}>Delete</button>
                        </div>
                        <div>
                            <button className="btnquiz">Share Report</button>
                            <button className="btnquiz" onClick={() => window.print()}>
                                Print Report
                            </button>
                            <a href="/static/QuizSecurespreadsheet.ods">
                                <button className="btnquiz">Download Report</button>
                            </a>
                        </div>
                    </div>
                    {quizResult && (
                        <div className="securityalertheader" id="securityalertheader">
                            <div>
                                Total Possible Points:{" "}
                                {100}
                            </div>
                            <div>
                                Points Achieved:{" "}
                                {parseInt(id) === parseInt(activeQuizReportId) ? quizResult.quiz_result.percentage : extraQuizResult.filter((item)=>{return activeQuizReportId === item?.id })[0]?.percentage}
                            </div>
                            <div>Percentage Result: {parseInt(id) === parseInt(activeQuizReportId) ? quizResult?.quiz_result?.percentage : extraQuizResult.filter((item)=>{return activeQuizReportId === item?.id })[0]?.percentage}%</div>
                            <div>
                                <button className="alerts-button">
                                    {parseInt(id) === parseInt(activeQuizReportId) ? quizResult.quiz_result.securityalerts : extraQuizResult.filter((item)=>{return activeQuizReportId === item?.id })[0]?.securityalerts} Security Alerts Found
                                </button>
                            </div>
                        </div>
                    )}
                    <table className="report-answerreport">
                        <tbody>
                            <tr>
                                <th>Auto Grade/Security Alerts</th>
                                <th>Question and Answers</th>
                            </tr>
                            {displayQuestion?.map((question, index) => (
                                <>
                                    <tr className='none' key={question.question_id}>
                                        <td style={{ width: "73%" }} className="ans-td">
                                            <div className="reports-questionanswer" id="report_Questionandanswer">
                                                <div className="Question-container">
                                                    <div>
                                                        <b>Q.{index + 1}</b>
                                                    </div>
                                                    <ContentEditable disabled={true} tagName="div" className="reports_question" style={{
                                                        overflow: "hidden",
                                                        wordWrap: "break-word",
                                                        maxWidth: "1000px", 
                                                        fontWeight: "700",
                                                    }} html={`<pre>${question.question_title}</pre>`} />
                                                </div>
                                                <div className="reports-questionanswer" style={{ flexDirection: "row" }}>
                                                    <div>
                                                        <b>Answer:</b>
                                                    </div>
                                                    <div>
                                                        {question.question_graded_nongraded === 'non-graded' ?
                                                            (
                                                                <>
                                                                    <div className="reports_question" style={{ overflow: "hidden", wordWrap: "break-word", maxWidth: "1000px", fontWeight: "400", fontSize: "16px", }}>
                                                                        {question?.question_type === "add_document" && (
                                                                            <Link to={BACKEND_URL + "private-media/" + question?.file_answer} download={question?.file_answer?.split("/")?.slice(-1)} > {question?.file_answer && (BACKEND_URL + "private-media/" + question?.file_answer)}</Link>
                                                                        )

                                                                        }
                                                                        <ContentEditable disabled={true} html={`${question?.answer?.text}`} tagName="pre" style={{ overflow: "hidden", wordWrap: "break-word", maxWidth: "1000px", fontWeight: "400", fontSize: "16px", }} />
                                                                    </div>
                                                                </>
                                                            )
                                                            : (
                                                                Object.entries(question.options).map(([key, value], index) => (
                                                                    <div className="option-container" key={index}>
                                                                        <div className={`option-container-radio ${question?.answer?.includes(key) ? (question?.question_tag) : question?.correct_answers?.includes(key) && "_correct"} ${question?.question_type === "multiple_response" && "mrq"} ${question?.correct_answers?.includes(key) && "_correct"}`} >
                                                                            <div className={`simulated-radio ${question?.answer?.includes(key) ? (question?.question_tag) : question?.correct_answers?.includes(key) && "_correct"} ${question?.question_type === "multiple_response" && "mrq"} ${question?.correct_answers?.includes(key) && "_correct"}`} ></div>

                                                                            <ContentEditable disabled={true} html={value} htmlFor={`option_${key}`} tagName="label" style={{ overflow: "hidden", wordBreak: "break-all", wordWrap: "break-word", maxWidth: "1000px", fontWeight: "400", fontSize: "16px", }} />
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            )}

                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="ans-td">{
                                            question.question_graded_nongraded === 'non-graded' ?
                                                (
                                                    <div className="reports_question" style={{ overflow: "hidden", wordWrap: "break-word", maxWidth: "1000px" }}>
                                                        <div className={`tag-container${activeQuizStatus === "In Progress" && question.question_tag === "timeout" ?"_":""} ${question.question_tag}`}>
                                                            {activeQuizStatus === "In Progress" && question.question_tag === "timeout" ? "-" : TagVerbose[question.question_tag]}
                                                        </div>
                                                        <br />
                                                        <div className="points_of_reports">
                                                            {
                                                                question.question_type === "essay" ?
                                                                    <Link className="points_of_reports" href="">
                                                                        {
                                                                            "Edit Points"
                                                                        }
                                                                    </Link> :
                                                                    parseInt(100 / parseInt(quizResult.quiz_result.total_number_of_questions)) + "  points"
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div className="reports_question" style={{ overflow: "hidden", wordWrap: "break-word", maxWidth: "1000px" }}>
                                                        <div className={`tag-container${activeQuizStatus === "In Progress" && question.question_tag === "timeout" ?"_":""} ${question.question_tag}`}>
                                                            {activeQuizStatus === "In Progress" && question.question_tag === "timeout" ? "-": TagVerbose[question.question_tag]}
                                                        </div>
                                                        <br />
                                                        <div className="points_of_reports">
                                                            {question.question_tag === "correct" ? parseInt(100 / parseInt(quizResult.quiz_result.total_number_of_questions)) : 0}{" "}
                                                            Points
                                                        </div>
                                                    </div>
                                                )
                                        }</td>
                                    </tr >

                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div >

            {/* <ReportPage /> */}
        </>


    )
}
