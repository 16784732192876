import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";

const QuestionTypeTooltipText = {
    "Multiple Choice Question": (
        <Tooltip className="main-tooltip">
            <div className="tooltip-popup">
                <div className="tooltip-head">Multiple Choice Question</div>
                <div className="tooltip-body">
                    <div className="tooltip-info">
                        Allow you to provide multiple possible answers in which
                        only one is correct.
                    </div>
                    <div className="tooltip-question">
                        Example:
                        <div className="question">
                            The compliment of blue is?
                        </div>
                        <div className="options">
                            <input type="radio" id="opt_1" />
                            <label htmlFor="opt_1">Red</label>
                            <br />
                            <input type="radio" id="opt_2" />
                            <label htmlFor="opt_2">Orange</label>
                            <br />
                            <input type="radio" id="opt_3" />
                            <label htmlFor="opt_3">Yellow</label>
                            <br />
                            <input type="radio" id="opt_4" />
                            <label htmlFor="opt_4">Purple</label>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    ),
    "Multiple Response Question": (
        <Tooltip className="main-tooltip">
            <div className="tooltip-popup">
                <div className="tooltip-head">Multiple Response Question</div>
                <div className="tooltip-body">
                    <div className="tooltip-info">
                        Allow you to provide multiple answers where more than
                        one answer is correct.
                    </div>
                    <div className="tooltip-question">
                        Example:
                        <div className="question">What are your hobbies?</div>
                        <div className="options">
                            <input type="checkbox" id="opt_1" />
                            <label htmlFor="opt_1">Reading</label>
                            <br />
                            <input type="checkbox" id="opt_2" />
                            <label htmlFor="opt_2">Traveling</label>
                            <br />
                            <input type="checkbox" id="opt_3" />
                            <label htmlFor="opt_3">Gardening</label>
                            <br />
                            <input type="checkbox" id="opt_4" />
                            <label htmlFor="opt_4">Cooking</label>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    ),
    "True or False Question": (
        <Tooltip className="main-tooltip">
            <div className="tooltip-popup">
                <div className="tooltip-head">True or False Question</div>
                <div className="tooltip-body">
                    <div className="tooltip-info">
                        True - False questions are composed of a statement.
                        Allow respondents to indicate whether the statement is
                        true or false.
                    </div>
                    <div className="tooltip-question">
                        Example:
                        <div className="question">
                            The moon is always seen at night.
                        </div>
                        <div className="options">
                            <input type="radio" id="opt_1" />
                            <label htmlFor="opt_1">True</label>
                            <br />
                            <input type="radio" id="opt_2" />
                            <label htmlFor="opt_2">False</label>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    ),
    "Yes or No Question": (
        <Tooltip className="main-tooltip">
            <div className="tooltip-popup">
                <div className="tooltip-head">Yes or No Question</div>
                <div className="tooltip-body">
                    <div className="tooltip-info">
                        Allow respondents to answer a question with a simple,
                        yes or no.
                    </div>
                    <div className="tooltip-question">
                        Example:
                        <div className="question">
                            Are you ready to take quiz?
                        </div>
                        <div className="options">
                            <input type="radio" id="opt_1" />
                            <label htmlFor="opt_1">Yes</label>
                            <br />
                            <input type="radio" id="opt_2" />
                            <label htmlFor="opt_2">No</label>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    ),
    "Essay Question": (
        <Tooltip className="main-tooltip">
            <div className="tooltip-popup">
                <div className="tooltip-head">Essay Question</div>
                <div className="tooltip-body">
                    <div className="tooltip-info">
                        Allow respondents to answer a question with a detailed
                        text based answer.
                    </div>
                    <div className="tooltip-question">
                        Example:
                        <div className="question">
                            Who is your role model and describe why? Describe in
                            200 words
                        </div>
                        <div className="options">
                            <textarea placeholder="Start your answer.."></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    ),
    "Video Response": (
        <Tooltip className="main-tooltip">
            <div className="tooltip-popup">
                <div className="tooltip-head">Video Response</div>
                <div className="tooltip-body">
                    <div className="tooltip-info">
                        Allow respondents to answer a question in a recorded
                        video.
                    </div>
                    <div className="tooltip-question">
                        Example:
                        <div className="question">
                            Please explain the most important digital marketing
                            channels.
                        </div>
                        <div className="options">
                            <img src="/images/videoquestion.png" alt="" />
                            <button>Start Recording</button>
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    ),
    "Rating Scale": (
        <Tooltip className="main-tooltip">
            <div className="tooltip-popup">
                <div className="tooltip-head">Rating Scale</div>
                <div className="tooltip-body">
                    <div className="tooltip-info">
                        Allow respondents to indicate how they feel about
                        something by selecting stars on a scale.
                    </div>
                    <div className="tooltip-question">
                        Example:
                        <div className="question">
                            How many stars would you give our mobile app? 5
                            stars is highest.
                        </div>
                        <div className="options">
                            <div className="star-div">
                                <AiFillStar className="star" />
                                <AiFillStar className="star" />
                                <AiFillStar className="star" />
                                <AiFillStar className="star" />
                                <AiFillStar className="star" color="black" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    ),
    "Ranking Question": (
        <Tooltip className="main-tooltip">
            <div className="tooltip-popup">
                <div className="tooltip-head">Ranking Question</div>
                <div className="tooltip-body">
                    <div className="tooltip-info">
                        Allow respondents to compare items to each other and
                        rank them in order of preference.
                    </div>
                    <div className="tooltip-question">
                        Example:
                        <div className="question">
                            Ranking the following with 1 being best
                        </div>
                        <div className="options ranking-options">
                            <span>
                                <FontAwesomeIcon
                                    icon={faGripVertical}
                                    className="question-drag-icon"
                                    size="sm"
                                />
                                {"    "}
                                <input
                                    type="number"
                                    className="ranking-number"
                                    value={1}
                                />{" "}
                                <label>Japanese Bistro</label>
                            </span>
                            <span>
                                <FontAwesomeIcon
                                    icon={faGripVertical}
                                    className="question-drag-icon"
                                    size="sm"
                                />
                                {"    "}
                                <input
                                    type="number"
                                    className="ranking-number"
                                    value={2}
                                />{" "}
                                <label>Italian Kitchen</label>
                            </span>
                            <span>
                                <FontAwesomeIcon
                                    icon={faGripVertical}
                                    className="question-drag-icon"
                                    size="sm"
                                />
                                {"    "}
                                <input
                                    type="number"
                                    className="ranking-number"
                                    value={3}
                                />{" "}
                                <label>Chinese Chekers </label>
                            </span>
                            <span>
                                <FontAwesomeIcon
                                    icon={faGripVertical}
                                    className="question-drag-icon"
                                    size="sm"
                                />
                                {"    "}
                                <input
                                    type="number"
                                    className="ranking-number"
                                    value={4}
                                />{" "}
                                <label>Greek Dinner</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    ),
    "Matrix Question": (
        <Tooltip className="main-tooltip">
            <div className="tooltip-popup">
                <div className="tooltip-head">Matrix Question</div>
                <div className="tooltip-body">
                    <div className="tooltip-info">
                        Allows respondents to rate opinions in a table format.
                        Rows represent opinion statements, and columns provide
                        response options for feedback collection.
                    </div>
                    <div className="tooltip-question">
                        Example:
                        <div className="question">
                            How satisfied are you with the following brands?
                        </div>
                        <div className="options ranking-options">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Very Satisfied</th>
                                        <th>Somewhat Satisfied</th>
                                        <th>Dissatisfied</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Mercedes</td>
                                        <td>
                                            <input type="radio" />
                                        </td>
                                        <td>
                                            <input type="radio" />
                                        </td>
                                        <td>
                                            <input type="radio" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Toyota</td>
                                        <td>
                                            <input type="radio" />
                                        </td>
                                        <td>
                                            <input type="radio" />
                                        </td>
                                        <td>
                                            <input type="radio" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Honda</td>
                                        <td>
                                            <input type="radio" />
                                        </td>
                                        <td>
                                            <input type="radio" />
                                        </td>
                                        <td>
                                            <input type="radio" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    ),
    "Upload a File": (
        <Tooltip className="main-tooltip">
            <div className="tooltip-popup">
                <div className="tooltip-head">Upload a File</div>
                <div className="tooltip-body">
                    <div className="tooltip-info">
                        Ask respondents to upload a file.
                        <br />
                        <br />
                        Supported file types:
                        <br />
                        Text Documents: .doc, .docx, .pdf, .txt, .rtf.
                        <br />
                        Presentations: .ppt, .pptx.
                        <br />
                        Spreadsheets: .xls, .xlsx, .csv.
                        <br />
                        Images: .jpg, .jpeg, .png, .gif, .bmp, .svg.
                        <br />
                        Audio: .mp3, .wav, .aac.
                        <br />
                        Video: .mp4, .avi, .mov, .wmv.
                        <br />
                        Compressed Files: .zip, .rar.
                    </div>
                    <div className="tooltip-question">
                        Example:
                        <div className="question">Upload your Resume.</div>
                        <div className="options ranking-options">
                            Maximum Upload Limit - 100 MB
                            <br />
                            Supported file types: .doc, .docx, .pdf, .txt, .rtf.
                            <button>Upload File</button>
                        </div>
                    </div>
                </div>
            </div>
        </Tooltip>
    ),
};

export default QuestionTypeTooltipText;
