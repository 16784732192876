const getLocalData = () => {
    if(document.URL.includes('preview')){
        let local_data = sessionStorage.getItem('completeQuestions');
        if(local_data){
            try {
                return parseInt(local_data);
            } catch (error) {
                return 1
            }
        }else{
            return 1
        }
    }else{
        return 1
    }
}

const reducer = (state = getLocalData(), action) => {
    if (action.type === "completeQuestion") {
        return action.payload;
    } else {
        return state;
    }
};

export default reducer;
