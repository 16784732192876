import QuizApi from "../../services/QuizApi";
import "../../assets/styles/Settings.css";
import { Link, useParams } from "react-router-dom";
import { MainNavbar } from "../Common/Navbar";
import React, { useEffect, useState } from "react";
import Sidebar from "../Common/Sidebar";

function QuizTracking() {
  const { quizId } = useParams();
  const [settings, setSettings] = useState(null);
  const links = [
    { to: `/general-settings/${quizId}`, text: "General Settings" },
    { to: `/scoring-option/${quizId}`, text: "Scoring Options" },
    { to: `/quiz-tracking/${quizId}`, text: "Quiz Tracking" },
    { to: `/security-settings/${quizId}`, text: "Browser Restrictions" },
    {
      to: `/smart-proctoring-system/${quizId}`,
      text: "Proctoring and Security",
    },
    { to: `/lead-integration/${quizId}`, text: "Lead Integration" },
  ];

  const [quizTracking, setQuizTracking] = useState({});
  const [quizTitle, setQuizTitle] = useState("");

  useEffect(() => {
    // Fetch quiz settings using the QuizApi instance
    try {
      QuizApi.get(`/quiztracking/${quizId}/`).then((response) => {
        setSettings(response.data);
        setQuizTitle(response.data.quiz_details.quiztitle);
      });
    } catch (error) {
      console.error("Error getting quiz settings:", error);
    }
  }, [quizId]);

  const handleSelectChange = (event, key) => {
    const { value, type, checked } = event.target;
    if (type === "checkbox") {
      setSettings({
        ...settings,
        [key]: checked ? 1 : 0,
      });
    } else {
      setSettings({
        ...settings,
        [key]: value,
      });
    }

    QuizApi.patch(`/quiztracking/${quizId}/`, {
      [key]: type === "checkbox" ? (checked ? 1 : 0) : value,
    })
      .then((response) => {
        console.log(
          `Updated ${key} to ${
            type === "checkbox" ? (checked ? 1 : 0) : value
          } in the backend.`
        );
      })
      .catch((error) => {
        console.error(`Error updating ${key} in the backend: ${error}`);
      });
  };

  useEffect(()=>{
    QuizApi.get(`/quiztracking/${quizId}`).then(resp=>{
      setQuizTracking(resp?.data?.quiz_tracking);
      setSettings(resp?.data?.quiz_tracking);
    }).catch(error=>{
      console.log(error);
    })

  },[])

  return (
    <div>
      {settings ? (
        <div>
          <MainNavbar />
          <Sidebar quiztitle={quizTitle} links={links} />
          <div className="containermain quizsettings">
            <h1 className="main_heading">&nbsp;Quiz Tracking</h1>
            <div style={{ paddingLeft: "3%" }}>
              <p>
                &nbsp;&nbsp;Select the information to ask the quiz taker before
                the quiz starts. This information will be used to identify the
                quiz taker.<br></br>&nbsp;&nbsp;If all identifying information
                is unselected, the quiz will be considered anonymous.
              </p>
              <br></br>
              <div className="trackingquestiondiv">
                <div className="quiztrackingoptions">
                  <strong>Primary Questions</strong>
                </div>
                <br></br>
                <div className="quiztrackingoptions">
                  <input
                    type="checkbox"
                    className="enterkeyevent"
                    onChange={(e) => handleSelectChange(e, "name")}
                    onBlur={(e) => handleSelectChange(e, "name")}
                    onKeyUp={(e) => handleSelectChange(e, "name")}
                    value={settings.name}
                    checked={settings.name}
                  ></input>
                  Name
                </div>
                <br></br>
                <div className="quiztrackingoptions">
                  <input
                    type="checkbox"
                    className="enterkeyevent"
                    onChange={(e) => handleSelectChange(e, "emailaddress")}
                    onBlur={(e) => handleSelectChange(e, "emailaddress")}
                    onKeyUp={(e) => handleSelectChange(e, "emailaddress")}
                    value={settings.emailaddress}
                    checked={settings.emailaddress}
                  ></input>
                  Email Address
                </div>
                <br></br>
                <div className="quiztrackingoptions">
                  <input
                    type="checkbox"
                    className="enterkeyevent"
                    onChange={(e) => handleSelectChange(e, "phonenumber")}
                    onBlur={(e) => handleSelectChange(e, "phonenumber")}
                    onKeyUp={(e) => handleSelectChange(e, "phonenumber")}
                    value={settings.phonenumber}
                    checked={settings.phonenumber}
                  ></input>
                  Phone Number
                </div>
                <br></br>
                <div className="quiztrackingoptions">
                  <input
                    type="checkbox"
                    className="enterkeyevent"
                    onChange={(e) => handleSelectChange(e, "address")}
                    onBlur={(e) => handleSelectChange(e, "address")}
                    onKeyUp={(e) => handleSelectChange(e, "address")}
                    value={settings.address}
                    checked={settings.address}
                  ></input>
                  Address
                </div>
                <br></br>
                {/* <div className="quiztrackingoptions">
                  <input
                    type="checkbox"
                    className="enterkeyevent"
                    onChange={(e) => handleSelectChange(e, "collegename")}
                    onBlur={(e) => handleSelectChange(e, "collegename")}
                    onKeyUp={(e) => handleSelectChange(e, "collegename")}
                    value={settings.collegename}
                    checked={settings.collegename}
                  ></input>
                  College Name
                </div> */}
                <div
                  className="quiztrackingoptions"
                  style={{ display: "inline-block" }}
                >
                  <input
                    type="checkbox"
                    className="enterkeyevent"
                    onChange={(e) => handleSelectChange(e, "ispasswordenabled")}
                    onBlur={(e) => handleSelectChange(e, "ispasswordenabled")}
                    onKeyUp={(e) => handleSelectChange(e, "ispasswordenabled")}
                    value={settings.ispasswordenabled}
                    checked={settings.ispasswordenabled}
                  ></input>
                  Password
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {settings.ispasswordenabled ? (
                  <input
                    type="text"
                    className="quizTrackingPasswordField"
                    id="quizTrackinPassword"
                    onChange={(e) => handleSelectChange(e, "password")}
                    onBlur={(e) => handleSelectChange(e, "password")}
                    onKeyUp={(e) => handleSelectChange(e, "password")}
                    value={settings.password}
                  ></input>
                ) : null}
                <br></br>
                <br></br>
                <div className="quiztrackingoptions additionalquestions">
                  <strong>Additional Questions</strong>
                  <button
                    id="askquestionlink"
                    type="submit"
                    name="additionalquesubmit"
                  >
                    + &nbsp; Ask More Questions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading settings...</p>
      )}
    </div>
  );
}

export default QuizTracking;
