import { faArrowLeft, faArrowRightLong, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range';
import PropTypes from 'prop-types';
import { LuCalendarSearch } from "react-icons/lu";
import { useSearchParams } from 'react-router-dom';


export default function DropDown(props) {
    const dateOptions = [
        { value: '1H', label: 'Last hour', f_key: 'H' },
        { value: '24H', label: 'Last 24 hours', f_key: 'H' },
        { value: '7D', label: 'Last 7 days', f_key: 'D' },
        { value: '14D', label: 'Last 14 days', f_key: 'D' },
        { value: '30D', label: 'Last 30 days', f_key: 'D' },
        { value: '90D', label: 'Last 90 days', f_key: 'D' },
        { value: 'ALL', label: 'All Results', f_key: 'A' },
        { value: '1Y', label: 'Custom Date Range', f_key: 'C' },
    ];

    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedOption, setSelectedOption] = useState({
        value: searchParams.get('fvk') || 'ALL',
        label: searchParams.get('fvk') ? dateOptions.find(item => item.value === searchParams.get('fvk')).label : 'All Results',
        f_key: searchParams.get('f_key') || 'A',
        startTime: searchParams.get('st') || "",
        endTime: searchParams.get('et') || "",
    });
    const dropdownMain = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isCalenderOpen, setIsCalenderOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState([
        {
            startDate: searchParams.get('st') ? new Date(searchParams.get('st')) : new Date(),
            endDate: searchParams.get('et') ? new Date(searchParams.get('et')) : new Date(),
            key: 'selection',
            f_key: 'C',
            value: '1Y',
        }
    ]);

    console.log(selectedDate);

    const calenderDateChange = (item) => {
        setSelectedDate([item.selection]);
    }

    const handleApplyClick = () => {
        setIsCalenderOpen(false);
        setIsDropdownOpen(false);
        const startDate = selectedDate[0].startDate.toLocaleString('default', { month: 'short', day: 'numeric' });
        const endDate = selectedDate[0].endDate.toLocaleString('default', { month: 'short', day: 'numeric' });
        let newEndDate = new Date(selectedDate[0].endDate);
        newEndDate.setDate(newEndDate.getDate() + 1);
        if(selectedDate[0].startDate === selectedDate[0].endDate){
            setSelectedOption({ startTime: selectedDate[0].startDate.toISOString(), endTime: newEndDate.toISOString(), label: `${startDate}`, value: '1Y', f_key: 'C' });
        }else{
            setSelectedOption({ startTime: selectedDate[0].startDate.toISOString(), endTime: newEndDate.toISOString(), label: `${startDate} - ${endDate}`, value: '1Y', f_key: 'C' });
        }
    }

    const handleDropBtnClick = () => {
        if (!isDropdownOpen && !isCalenderOpen) {
            setIsDropdownOpen(true);
        } else {
            setIsCalenderOpen(false);
            setIsDropdownOpen(false);
        }
    }

    const handleDateChange = (e) => {
        let endTime = new Date();
        let startTime = new Date();
        switch (e.value) {
            case "1H":
                startTime = new Date();
                startTime.setHours(startTime.getHours() - 1);
                break;
            case "24H":
                startTime = new Date();
                startTime.setHours(startTime.getHours() - 24);
                break;
            case "7D":
                startTime = new Date();
                startTime.setDate(startTime.getDate() - 7);
                break;
            case "14D":
                startTime = new Date();
                startTime.setDate(startTime.getDate() - 14);
                break;
            case "30D":
                startTime = new Date();
                startTime.setDate(startTime.getDate() - 30);
                break;
            case "90D":
                startTime = new Date();
                startTime.setDate(startTime.getDate() - 90);
                break;
            case "ALL":
                startTime = new Date(0);
                endTime = new Date();
                break;
            case "1Y":
                setIsDropdownOpen(false);
                setIsCalenderOpen(true);
                return;
            default:
                return;
        }
        setIsDropdownOpen(false);
        console.log(startTime.toISOString(),"\n", endTime.toISOString());
        setSelectedOption({value: e.value, label: e.label, f_key: e.f_key, startTime: startTime.toISOString(), endTime: endTime.toISOString()});
    }

    useEffect(() => {
        const handleClick = (e) => {
            if (dropdownMain.current && !dropdownMain.current.contains(e.target)) {
                setIsDropdownOpen(false);
                setIsCalenderOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        }
    }, [dropdownMain]);

    useEffect(() => {
        props.setDateFilterChange(selectedOption);
    }, [selectedOption]);

    return (
        <div className="dropdown-main" ref={dropdownMain}>
            <div className="dropdown-div">
                <button className="drop-btn" onClick={handleDropBtnClick} title={selectedOption.label}> {selectedOption.value === '1Y' ? <LuCalendarSearch className='search-calender' /> : <FontAwesomeIcon icon={faCalendarDays} />}
                </button>
                {
                    isDropdownOpen && (
                        <div className="dropdown-item">
                            {dateOptions.map((option, index) => (
                                <span className={`dp-item ${selectedOption.value === option.value && "active"}`} key={index} onClick={() => { handleDateChange(option) }}>{option.label} {option.value === "1Y" && <FontAwesomeIcon className='abs-dt-forward' icon={faArrowRightLong} />} </span>
                            ))}
                        </div>
                    )
                }
                {
                    isCalenderOpen && (
                        <div className="calendar-div">
                            <DateRange
                                editableDateInputs={false}
                                onChange={calenderDateChange}
                                moveRangeOnFirstSelection={false}
                                ranges={selectedDate}
                                minDate={new Date(props?.accountCreatedDate.current)}
                                maxDate={new Date()}
                            />
                            <div className="action-btn">
                                <button className='back-btn' onClick={() => { setIsCalenderOpen(false); setIsDropdownOpen(true); }}> <FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                                <button className='apply-btn' onClick={handleApplyClick}>Apply</button>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}



DropDown.propTypes = {
    setDateFilterChange: PropTypes.func.isRequired,
};